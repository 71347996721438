<template>
  <div>
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <div class="conditions">
            <el-input
              class="ipt-default"
              style="width: 300px"
              v-model="advancedForm.name"
              placeholder="请输入商户名称/活动名称/活动编号"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="shop_name" label="商户名称" />
        <el-table-column prop="goods_name" label="活动名称" />
        <el-table-column prop="goods_sn" label="活动编号" />
        <el-table-column prop="price" label="活动价格" />
        <el-table-column prop="re_num" label="退款笔数" />
        <el-table-column prop="or_num" label="实际成交笔数" />
        <el-table-column label="退款比例">
          <template slot-scope="scope">
            {{scope.row.rate * 100}}%
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Shop from "@/api/shop";
export default {
  name: "refoundTop",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        name: "", // 根据关键字搜索
      },
    };
  },
  mounted() {
    this.GET_List();
  },
  methods: {
    /** 筛选 */
    searchEvent() {
      this.params.page_no = 1;
      this.params.name = this.advancedForm.name;
      this.GET_List();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },
    /** 获取列表 */
    GET_List() {
      API_Shop.refundActTop5(this.params)
        .then((res) => {
          this.tableData = res;
        })
        .catch({});
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.inner-toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
